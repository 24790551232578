<template lang="pug">
div(v-if="isShow")
  //  Tags with side effect (<script> and <style>) are ignored in client component templates.
  //  あえての対応
  //  script(type="text/javascript" :src="'//xa.shinobi.jp/ufo/' + ninjaPageId")
  component(:is="'script'" type="text/javascript" :src="'//xa.shinobi.jp/ufo/' + ninjaPageId")
  noscript
    a(:href="'//xa.shinobi.jp/bin/gg?' + ninjaPageId" target="_blank" rel="noopener" aria-label="shinobi" title="shinobi")
      img(:src="'//xa.shinobi.jp/bin/ll?' + ninjaPageId" border="0" alt="shinobi")
    br
    span(style="font-size:9px")
      img(style="margin:0;vertical-align:text-bottom;" src="//img.shinobi.jp/tadaima/fj.gif" width="19" height="11" alt="shinobi")
</template>
<script setup lang="ts">
import { ninjaPageId } from "~/composables/useRadiHead"

const config = useRuntimeConfig()
const isProduction = config.public.ENV === "production"
const isShow = computed(() => isProduction && ninjaPageId.value !== "" )

</script>
