import {onMounted} from "vue";

export const useCypress = () => {
    onMounted(() => {
        const route = useRoute()
        if (route.query.debug !== undefined && route.query.debug === "cypress") {
            let targetElement = document.querySelectorAll(".is-in-screen")
            for (let i = 0; i < targetElement.length; i++) {
                targetElement[i].classList.remove("is-in-screen")
            }
            const selectors = ".burger-wrap,.entry-nav,.footer-contact,header,.recruit_header,.nav-wrap"
            targetElement = document.querySelectorAll(selectors)
            for (let i = 0; i < targetElement.length; i++) {
                targetElement[i].setAttribute("style", "position: absolute;")
            }
        }
    })

    return {}
}
