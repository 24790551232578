import VueScrollTo from "vue-scrollto"

export const useScrollDownAndFadein = () => {

  const onScroll = () => {
    const targetElement = document.querySelectorAll(".is-in-screen")
    for (let i = 0; i < targetElement.length; i++) {
      const getElementDistance =
        targetElement[i].getBoundingClientRect().top + targetElement[i].clientHeight * 0.5
      if (window.innerHeight > getElementDistance) {
        targetElement[i].classList.add("is-show")
      }
    }
  }

  onMounted(() => {
    const route = useRoute()
    const hash = route.hash
    if (hash && hash.match(/^#.+$/)) {
      setTimeout(() => {
        VueScrollTo.scrollTo(hash)
      }, 50)
    }
    onScroll()
    window.addEventListener("scroll", onScroll)
  })
  onBeforeUnmount(() => {
    window.removeEventListener("scroll", onScroll)
  })

  return {
  }
}
